import { mapState, mapMutations } from "vuex";
import dailyEntryService from "../../../services/daily_entry.service";
export default {
    data() {
        return {
            loader: false,
            valid: true,
            employee_id: null,
            time: 0,
            worker_count_and_times_id: null,
            modal_data: {
                size: "600px",
                title: this.$i18n.t("employeees.edit_hourly_fare"),
            },
            style_hourly_fare: [
                {
                    col: "12",
                    type: "number",
                    label: this.$i18n.t("employeees.hourly_fare"),
                    value_text: "hourly_fare",
                    value: "",
                    error: null,
                },
            ],
        }
    },
    computed: {
        ...mapState("daily_entry", ["hourly_fare"]),
        employee() {
            return this.style_form.filter((v) => v.value_text == "employee")[0].value;
        },
        start_time() {
            return this.style_form.filter(
                (v) => v.value_text == "start_actual_time"
            )[0].value;
        },
        end_time() {
            return this.style_form.filter((v) => v.value_text == "end_actual_time")[0]
                .value;
        },
    },
    watch: {
        employee: {
            handler: function (value) {
                console.log("employee", value);
                if (value instanceof Object) {
                    this.set_hourly_fare(value.hourly_fare);
                    this.employee_id = value.id;
                } else {
                    this.employee_id = value;
                }
            },
        },
        start_time: {
            handler: function (value) {
                // alert("start");
                if (this.end_time > value) {
                    // alert(value)
                    this.time = this.get_hour(this.end_time, value);
                }
            },
        },
        end_time: {
            handler: function (value) {
                // alert("end");
                if (value > this.start_time) {
                    //     alert(value)
                    this.time = this.get_hour(value, this.start_time);
                } else {
                    this.time = this.get_hour(value, this.start_time, 'date2');

                }
            },
        },
    },
    methods: {
        ...mapMutations("daily_entry", ["set_hourly_fare"]),

        get_hour(val1, val2, type = 'date1') {
            // alert(val1 + ' ' + val2)
            // تحويل الوقتين إلى كائنات تاريخ
            if (type == 'date2') {
                var date1 = new Date();
                date1.setDate(date1.getDate() + 1);
                date1.setHours(Number(val1.split(":")[0]));
                date1.setMinutes(Number(val1.split(":")[1]));
                if (val1.split(":")[2]) date1.setSeconds(Number(val1.split(":")[2]));
            } else {
                var date1 = new Date();
                date1.setHours(Number(val1.split(":")[0]));
                date1.setMinutes(Number(val1.split(":")[1]));
                if (val1.split(":")[2]) date1.setSeconds(Number(val1.split(":")[2]));

            }

            var date2 = new Date();
            date2.setHours(Number(val2.split(":")[0]));
            date2.setMinutes(Number(val2.split(":")[1]));
            if (val2.split(":")[2]) date2.setSeconds(Number(val2.split(":")[2]));

            // حساب الفرق بين الوقتين بالميلي ثانية
            console.log("date1 ", date1)
            console.log("date2 ", date2)
            var timeDiffMilliseconds = date1 - date2;
            // console.log(timeDiffMilliseconds);
            // تحويل الفرق إلى عدد الساعات
            var hoursDiff = Math.floor(timeDiffMilliseconds / (1000 * 60 * 60));
            // تحويل الفرق إلى عدد الساعات

            // console.log("hoursDiff", hoursDiff);
            return hoursDiff;
        },

        select_work(value) {
            console.log("value", value);
            return dailyEntryService.get_employee_by_project(value.id).then(
                (response) => {
                    console.log(response);
                    var employees = []
                    response.data.data.forEach(v => {
                        employees.push(v.employee);
                    });
                    this.style_form.map((v) => {
                        if (v.value_text == "employee") v.items = employees;
                        else if (v.value_text == "start_actual_time")
                            v.value = value.start_time;
                        else if (v.value_text == "end_actual_time") v.value = value.end_time;
                        return v;
                    });
                },
                (error) => {
                    console.log(error);

                    this.$store.commit("form/SET_NOTIFY", {
                        msg: this.$i18n.t("general.there is problem"),
                        type: "Danger",
                    });
                }
            );
            // console.log(value);
            // this.worker_count_and_times_id = value.id;
            // this.style_form = this.style_form.map((v) => {
            //     if (v.value_text == "employee") v.items = value.employee;
            //     else if (v.value_text == "start_actual_time")
            //         v.value = value.start_time;
            //     else if (v.value_text == "end_actual_time") v.value = value.end_time;

            //     return v;
            // });
        },

        edit_hourly_fare() {
            if (this.employee_id == null) {
                this.$store.commit("form/SET_NOTIFY", {
                    msg: this.$i18n.t("general.please select employee"),
                    type: "Warning",
                });
                return false;
            }
            this.$store.commit("form/SET_DIALOG", true);
            this.$store.commit("SET_FUNCTION", "edit_hourly_fare");
            this.style_hourly_fare[0].value = this.hourly_fare;
            this.$store.commit("form/SET_FORM_STYLE", this.style_hourly_fare);
        },


    },
}